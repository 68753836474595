import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";

export default function EnrollFormDialog({ uri, onClose }) {
  return (
    <Dialog
      onClose={onClose}
      open={uri !== ""}
      fullWidth
      maxWidth={"xl"}
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <iframe
          src={uri}
          title="ficha de cadastro do funcionário`"
          height={"100%"}
        />
      </DialogContent>
    </Dialog>
  );
}
